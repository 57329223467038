export const GOOGLE_AUTH_PLAY_MARKET =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'

export const GOOGLE_AUTH_APP_STORE =
  'https://apps.apple.com/app/google-authenticator/id388497605'

export const AUTHY_PLAY_MARKET =
  'https://play.google.com/store/apps/details?id=com.authy.authy'

export const AUTHY_APP_STORE =
  'https://apps.apple.com/app/twilio-authy/id494168017'

export const ONE_PASSWORD_PLAY_MARKET =
  'https://play.google.com/store/apps/details?id=com.onepassword.android'

export const ONE_PASSWORD_APP_STORE =
  'https://apps.apple.com/app/1password-8-password-manager/id1511601750'
