import React from 'react'
import cs from 'classnames'
import { Headline4, Subheading } from 'components/typography'
import useWindowSize from 'hooks/useWindowSize'
import Checked from 'images/icons/checked-icn.svg'
import styles from './Steps.module.scss'

interface StepsProps {
  currentStep: number
  className?: string
  stepsNameArray?: string[]
}

const Steps: React.FC<StepsProps> = ({
  currentStep,
  className,
  stepsNameArray,
}) => {
  const windowSize = useWindowSize()

  return (
    <div className={cs(styles.steps, className)}>
      <div
        className={cs(
          styles.step,
          { [styles.current]: currentStep === 1 },
          { [styles.done]: currentStep > 1 },
        )}
      >
        {currentStep > 1 ? (
          <Checked />
        ) : (
          <Headline4 className={styles.circle}>1</Headline4>
        )}
        {windowSize.isBigScreen && (
          <Subheading className={styles.text}>
            {stepsNameArray ? stepsNameArray[0] : 'Download the app'}
          </Subheading>
        )}
        <div className={styles.line} />
      </div>
      <div
        className={cs(
          styles.step,
          { [styles.current]: currentStep === 2 },
          { [styles.done]: currentStep > 2 },
        )}
      >
        {currentStep > 2 ? (
          <Checked />
        ) : (
          <Headline4 className={styles.circle}>2</Headline4>
        )}
        {windowSize.isBigScreen && (
          <Subheading className={styles.text}>
            {stepsNameArray ? stepsNameArray[1] : 'Scan the QR code'}
          </Subheading>
        )}
        <div className={styles.line} />
      </div>
      <div
        className={cs(
          styles.step,
          { [styles.current]: currentStep === 3 },
          { [styles.done]: currentStep > 2 },
        )}
      >
        {currentStep > 3 ? (
          <Checked />
        ) : (
          <Headline4 className={styles.circle}>3</Headline4>
        )}
        {windowSize.isBigScreen && (
          <Subheading className={styles.text}>
            {stepsNameArray ? stepsNameArray[2] : 'Enter the code'}
          </Subheading>
        )}
      </div>
    </div>
  )
}

export default Steps
