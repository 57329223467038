import React, { ReactNode, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Field, Form } from 'react-final-form'
import { validatePasswordField } from 'helpers/validators'
import { SignInPageContext } from 'components/context/SignInPageContext'
import { Body, Headline1, Headline4Bold } from 'components/typography'
import Steps from 'components/ui/steps/Steps'
import useWindowSize from 'hooks/useWindowSize'
import Button from 'components/ui/button/Button'
import BackupCodes from './backup-codes/BackupCodes'
import Authenticators from './authenticators/authenticators'
import QrLoginSection from './qr-login-section/QrLoginSection'
import Input from 'components/ui/input/Input'
import styles from './LoginTwoFa.module.scss'

const LoginTwoFa: React.FC = observer(() => {
  const windowSize = useWindowSize()
  const signInPageContext = useContext(SignInPageContext)

  return (
    <div className={styles.wrapper}>
      <Steps currentStep={signInPageContext.stepNumber} />
      <div className={styles.content}>
        <Headline1 as='p' className={styles.mainTitle}>
          {signInPageContext.backupCodes
            ? 'Save the codes'
            : 'Enable two-factor auth'}
        </Headline1>
        {signInPageContext.stepNumber === 1 && (
          <>
            <Headline4Bold className={styles.title}>
              {!windowSize.isBigScreen && 'Step 1.'} Download the app
            </Headline4Bold>
            <Body>
              Download a supported authenticator app on your mobile device (such
              as Google Authenticator, Authly, IPassword, LastPass etc.)
            </Body>
            <Authenticators />
            <Button
              className={styles.next}
              isLoading={signInPageContext.isLoading}
              onClick={signInPageContext.handleFirstStep}
            >
              Next
            </Button>
          </>
        )}

        {signInPageContext.stepNumber === 2 && (
          <QrLoginSection
            otpLink={signInPageContext.otpLink}
            setStepNumber={signInPageContext.setStepNumber}
            textCode={signInPageContext.otpSecret}
          />
        )}
        {signInPageContext.stepNumber === 3 && !signInPageContext.backupCodes && (
          <>
            <Headline4Bold className={styles.title}>
              {!windowSize.isBigScreen && 'Step 3.'} Log in with your code
            </Headline4Bold>
            <Form onSubmit={signInPageContext.enableOtpCode}>
              {({ handleSubmit, values }): ReactNode => (
                <form onSubmit={handleSubmit}>
                  <Field name='code' validate={validatePasswordField}>
                    {({ input, meta }): ReactNode => (
                      <Input
                        error={
                          meta.touched
                            ? meta.error || signInPageContext.errorMessage
                            : ''
                        }
                        id='code'
                        title='Enter the 6-digit verification code generated'
                        type='text'
                        required
                        {...input}
                      />
                    )}
                  </Field>
                  <div className={styles.buttons}>
                    <Button
                      buttonType='button'
                      onClick={(): void => signInPageContext.setStepNumber(2)}
                      isSecondary
                    >
                      Back
                    </Button>
                    <Button
                      buttonType='submit'
                      isDisabled={!values.code}
                      isLoading={signInPageContext.isLoading}
                    >
                      Activate
                    </Button>
                  </div>
                </form>
              )}
            </Form>
          </>
        )}
        {signInPageContext.backupCodes && (
          <BackupCodes backupCodes={signInPageContext.backupCodes} />
        )}
      </div>
    </div>
  )
})

export default LoginTwoFa
