import React from 'react'
import { Subheading } from 'components/typography'
import {
  AUTHY_APP_STORE,
  AUTHY_PLAY_MARKET,
  GOOGLE_AUTH_APP_STORE,
  GOOGLE_AUTH_PLAY_MARKET,
  ONE_PASSWORD_APP_STORE,
  ONE_PASSWORD_PLAY_MARKET,
} from 'constants/authApplicationLinks'
import UiLink from 'components/ui/ui-link/UiLink'
import Authy from 'images/icons/authy-icn.svg'
import AppStore from 'images/icons/app-store.svg'
import GooglePlay from 'images/icons/google-play.svg'
import OnePassword from 'images/icons/1password-icn.svg'
import GoogleAuth from 'images/icons/google-authenticator-icn.svg'
import styles from '../LoginTwoFa.module.scss'

const Authenticators: React.FC = () => (
  <div className={styles.variants}>
    <div className={styles.variantBlock}>
      <Subheading className={styles.variant}>
        <GoogleAuth /> Google Authenticator
      </Subheading>
      <div className={styles.mobileStores}>
        <UiLink href={GOOGLE_AUTH_PLAY_MARKET}>
          <GooglePlay />
        </UiLink>
        <UiLink href={GOOGLE_AUTH_APP_STORE}>
          <AppStore />
        </UiLink>
      </div>
    </div>

    <div className={styles.variantBlock}>
      <Subheading className={styles.variant}>
        <Authy /> Authy
      </Subheading>
      <div className={styles.mobileStores}>
        <UiLink href={AUTHY_PLAY_MARKET}>
          <GooglePlay />
        </UiLink>
        <UiLink href={AUTHY_APP_STORE}>
          <AppStore />
        </UiLink>
      </div>
    </div>
    <div className={styles.variantBlock}>
      <Subheading className={styles.variant}>
        <OnePassword /> 1Password
      </Subheading>
      <div className={styles.mobileStores}>
        <UiLink href={ONE_PASSWORD_PLAY_MARKET}>
          <GooglePlay />
        </UiLink>
        <UiLink href={ONE_PASSWORD_APP_STORE}>
          <AppStore />
        </UiLink>
      </div>
    </div>
  </div>
)

export default Authenticators
