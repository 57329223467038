import React, { ReactNode, useContext, useState } from 'react'
import { Field, Form } from 'react-final-form'
import {
  Body,
  Headline1blue,
  Headline4,
  Subheading,
} from 'components/typography'
import { ApiHelperContext } from 'components/context/ApiHelperContext'
import { validateEmailField } from 'helpers/validators'
import Input from 'components/ui/input/Input'
import Button from 'components/ui/button/Button'
import Postman from 'images/icons/postman.svg'
import styles from './ResetPassword.module.scss'

interface Props {
  toSignIn: () => void
}

const ResetPassword: React.FC<Props> = ({ toSignIn }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [isPasswordSend, setIsPasswordSend] = useState(false)
  const apiHelper = useContext(ApiHelperContext)

  const onResetSubmit = async (values): Promise<void> => {
    const { error } = await apiHelper.forgotPassword(values)
    setErrorMessage(error)
    setIsPasswordSend(!error)
  }

  return (
    <div className={styles.container}>
      {!isPasswordSend ? (
        <>
          <Headline1blue as='h2'> Reset password</Headline1blue>
          <Body>
            Enter your email and we will send you there a link to reset your
            password
          </Body>
          <Form onSubmit={onResetSubmit}>
            {({ handleSubmit, values }): ReactNode => (
              <form onSubmit={handleSubmit}>
                <Field name='email' validate={validateEmailField}>
                  {({ input, meta }): ReactNode => (
                    <Input
                      error={meta.touched ? meta.error || errorMessage : ''}
                      id='email'
                      inputContainerClass={styles.inputContainer}
                      title='E-mail'
                      required
                      {...input}
                    />
                  )}
                </Field>
                <div className={styles.buttons}>
                  <Subheading className={styles.toSignIn} onClick={toSignIn}>
                    Sign In
                  </Subheading>
                  <Button buttonType='submit' isDisabled={!values.email}>
                    Send
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </>
      ) : (
        <div className={styles.sent}>
          <Headline4>
            If that email address is in our database, we will send you an email
            to reset your password.
          </Headline4>
          <Postman />
        </div>
      )}
    </div>
  )
}

export default ResetPassword
