import { validationConstants } from 'constants/validationConstants'

export const validateRequiredField = (value: string): string | undefined =>
  value ? undefined : validationConstants.FIELD_IS_REQUIRED_TEXT

export const validatePasswordField = (value: string): string | undefined => {
  if (value === undefined) {
    return validationConstants.FIELD_IS_REQUIRED_TEXT
  }

  return value.length < validationConstants.MIN_PASSWORD_LENGTH
    ? validationConstants.TOO_SHORT_PASSWORD
    : undefined
}

export const fieldMatchValidate = (values): { confirm: string | undefined } => {
  const errors = {
    confirm: undefined,
  }

  if (values.password !== values.confirm) {
    errors.confirm = validationConstants.PASSWORD_DO_NOT_MATCH
  }

  return errors
}

export const validateEmailField = (value: string): string | undefined => {
  if (value === undefined) {
    return validationConstants.FIELD_IS_REQUIRED_TEXT
  }

  return value?.search(
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ) === 0
    ? undefined
    : validationConstants.INVALID_EMAIL_TEXT
}
