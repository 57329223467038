import React, { Dispatch, SetStateAction } from 'react'
import useWindowSize from 'hooks/useWindowSize'
import { makeNotifications } from 'helpers/makeNotifications'
import { Body, Headline4, Subheading } from 'components/typography'
import QRCode from 'react-qr-code'
import Button from 'components/ui/button/Button'
import Copy from 'images/icons/copy-icn.svg'
import styles from './QrLoginSection.module.scss'

const QrLoginSection: React.FC<{
  textCode: string
  otpLink: string
  setStepNumber?: Dispatch<SetStateAction<number>>
}> = ({ textCode, setStepNumber, otpLink }) => {
  const windowSize = useWindowSize()
  const handleCopy = (): void => {
    navigator.clipboard.writeText(textCode)
    makeNotifications(true, 'Copied!', 800)
  }

  return (
    <>
      <div className={styles.section}>
        {windowSize.isMobile && (
          <Subheading className={styles.subheading}>
            Step 2. Scan the QR code
          </Subheading>
        )}
        <Body>
          Scan the QR code below using a supported authenticator app (such as
          Google Authenticator, Authly, IPassword, LastPass etc.)
        </Body>
        <div className={styles.qr}>
          <QRCode size={150} value={otpLink} />
        </div>
        <Body className={styles.tip}>
          Can’t scan the QR code? Enter this code into your authenticator app
          instead:
        </Body>
        <Headline4 className={styles.code} onClick={handleCopy}>
          {textCode} <Copy />
        </Headline4>
      </div>
      <div className={styles.buttons}>
        <Button onClick={(): void => setStepNumber(1)} isSecondary>
          Back
        </Button>
        <Button onClick={(): void => setStepNumber(3)}>Next</Button>
      </div>
    </>
  )
}

export default QrLoginSection
