import React, { ReactNode, useState } from 'react'
import { useRouter } from 'next/router'
import { Field, Form } from 'react-final-form'
import { UserLoginData } from 'interfaces/apiInterfaces'
import { NextJsApiHelper } from 'helpers/nextJsApiHelper'
import { validateEmailField, validatePasswordField } from 'helpers/validators'
import { Headline1blue, Subheading } from 'components/typography'
import LoginTwoFa from '../login-two-fa/LoginTwoFa'
import Input from 'components/ui/input/Input'
import Button from 'components/ui/button/Button'
import styles from './SignInForm.module.scss'
import cs from 'classnames'

interface Props {
  onResetPassword: () => void
  className?: string
}

const SignInForm: React.FC<Props> = ({ onResetPassword }) => {
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)
  const [errorField, setErrorField] = useState<UserLoginData>({})
  const [isCodeInputShown, setIsCodeInputShown] = useState(false)
  const nextJsApiHelperInstance = new NextJsApiHelper()
  const [isOtpStepsShown, setIsOtpStepsShown] = useState(false)

  const getAccess = async (user: UserLoginData): Promise<void> => {
    setIsLoading(true)
    const { result, error } = await nextJsApiHelperInstance.getAccess(user)
    setIsLoading(false)

    if (error) {
      switch (error) {
        case '2FA code is missing':
          setIsCodeInputShown(true)
          break
        case 'Invalid 2FA code':
          setErrorField({ code: error })
          break
        case 'Invalid email or password':
          setErrorField({ email: error })
          break
        default:
          break
      }
    } else if (result.isWith2FA && result.isOtpRequired) {
      router.push('/')
    } else {
      setIsOtpStepsShown(true)
    }
  }

  const onEmailFormSubmit = (values: UserLoginData): void => {
    getAccess(values)
  }

  return isOtpStepsShown ? (
    <LoginTwoFa />
  ) : (
    <div className={styles.forms}>
      <div className={styles.standardForm}>
        <Headline1blue as='p'>Sign in</Headline1blue>
        <Form
          onSubmit={onEmailFormSubmit}
          validate={(): UserLoginData => {
            const errors = {}

            return errors
          }}
        >
          {({ handleSubmit, values }): ReactNode => (
            <form onSubmit={handleSubmit}>
              <Field name='email' validate={validateEmailField}>
                {({ input, meta }): ReactNode => (
                  <Input
                    error={(meta.touched && meta.error) || errorField.email}
                    id='email'
                    inputContainerClass={cs(
                      'analytic-sensitive-data',
                      styles.inputContainer,
                    )}
                    title='E-mail'
                    type='email'
                    required
                    {...input}
                  />
                )}
              </Field>

              <Field name='password' validate={validatePasswordField}>
                {({ input, meta }): ReactNode => (
                  <Input
                    error={(meta.touched && meta.error) || errorField.password}
                    id='password'
                    inputContainerClass={cs(
                      'analytic-sensitive-data',
                      styles.inputContainer,
                    )}
                    title='Password'
                    type='password'
                    required
                    {...input}
                  />
                )}
              </Field>
              <Subheading
                className={styles.forgot}
                onClick={(): void => onResetPassword()}
              >
                Forgot password?
              </Subheading>
              {isCodeInputShown && (
                <Field name='code' validate={validatePasswordField}>
                  {({ input, meta }): ReactNode => (
                    <Input
                      error={(meta.touched && meta.error) || errorField.code}
                      id='code'
                      inputContainerClass={styles.inputContainer}
                      title='Auth app code'
                      type='text'
                      required
                      {...input}
                    />
                  )}
                </Field>
              )}
              <Button
                buttonType='submit'
                className={styles.btn}
                isDisabled={!values.email || !values.password}
                isLoading={isLoading}
              >
                Let’s go
              </Button>
            </form>
          )}
        </Form>
      </div>
    </div>
  )
}

export default SignInForm
