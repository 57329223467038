import { Store } from 'react-notifications-component'
import 'animate.css'

export const makeNotifications = (
  isSuccess,
  messageText,
  duration = 3000,
): void => {
  if (isSuccess) {
    Store.addNotification({
      message: messageText,
      type: 'success',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration,
      },
    })
  } else {
    Store.addNotification({
      message: messageText,
      type: 'danger',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration,
      },
    })
  }
}
