import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { Body, Headline4 } from 'components/typography'
import { makeNotifications } from 'helpers/makeNotifications'
import Button from 'components/ui/button/Button'
import cs from 'classnames'
import Copy from 'images/icons/copy-icn.svg'
import Check from 'images/icons/check-icn.svg'
import styles from './BackupCodes.module.scss'

const BackupCodes: React.FC<{
  backupCodes: string[]
  isProfilePage?: boolean
}> = ({ backupCodes, isProfilePage }) => {
  const [isChecked, setIsChecked] = useState(false)
  const router = useRouter()

  const handleCheckbox = (): void => {
    setIsChecked((prevState) => !prevState)
  }
  const handleCopy = (): void => {
    const codesToCopy = backupCodes.join(', ')
    navigator.clipboard.writeText(codesToCopy)
    makeNotifications(true, 'Copied!', 800)
  }
  const handleChangeRoute = (): void => {
    router.push('/')
  }

  return (
    <div className={styles.container}>
      <Headline4 className={styles.title}>Pay attention!</Headline4>
      <Body>
        Below you can see 5 one-time codes, you will need them if you restore
        access to your account. Save the codes and don&apos;t lose them. These
        codes are shown to you once.
      </Body>

      <div
        className={
          isProfilePage ? styles.profileCodeSection : styles.codeSection
        }
      >
        <div className={styles.profileCodesWrapper}>
          {backupCodes.map((code) => (
            <Body className={styles.code} key={code}>
              {code}
            </Body>
          ))}
        </div>
        <Button onClick={handleCopy} isSecondary>
          <Copy />
          Copy the codes
        </Button>
      </div>

      <div className={styles.agreement}>
        <div className={styles.info}>
          <div
            className={cs(styles.checkbox, isChecked && styles.checked)}
            onClick={handleCheckbox}
            onKeyDown={handleCheckbox}
            role='button'
            tabIndex={0}
          >
            {isChecked && <Check />}
          </div>
          <input checked={isChecked} type='checkbox' readOnly />
          <Body>
            I understand that codes are important to my security. I saved them
            in a safe place
          </Body>
        </div>

        <Button isDisabled={!isChecked} onClick={handleChangeRoute}>
          {isProfilePage ? 'Finish' : 'Go to Kruze'}
        </Button>
      </div>
    </div>
  )
}

export default BackupCodes
