import React, { useEffect, useState } from 'react'
import { RouterPaths } from 'constants/paths/routerPaths'
import { Headline1blue } from 'components/typography'
import { makeNotifications } from 'helpers/makeNotifications'
import { Message } from 'interfaces/pages-components-interfaces'
import useWindowSize from 'hooks/useWindowSize'
import UiLink from 'components/ui/ui-link/UiLink'
import SignInForm from 'components/pages/sign-in-page/sign-in-form/SignInForm'
import ResetPassword from 'components/pages/sign-in-page/reset-password/ResetPassword'
import Logo from 'images/icons/logo-icn.svg'
import SmallLogo from 'images/icons/logo-mobile-icn.svg'
import styles from './SignInPage.module.scss'

interface Props {
  notificationData: Message
}

const SignInPage: React.FC<Props> = ({ notificationData }) => {
  const [isConfirmFormShown, setIsConfirmFormShown] = useState(false)
  const windowSize = useWindowSize()
  useEffect(() => {
    if (notificationData) {
      makeNotifications(true, 'Your e-mail has been successfully confirmed!')
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      {windowSize.isDesktop ? (
        <div className={styles.left}>
          <div className={styles.static}>
            <Logo />
            <Headline1blue>
              Accounting, Finance,Tax & HR for Startups
            </Headline1blue>
          </div>
        </div>
      ) : (
        <UiLink className={styles.header} href={RouterPaths.HOME}>
          <SmallLogo />
        </UiLink>
      )}
      <div className={styles.right}>
        {isConfirmFormShown ? (
          <ResetPassword toSignIn={(): void => setIsConfirmFormShown(false)} />
        ) : (
          <SignInForm
            onResetPassword={(): void => setIsConfirmFormShown(true)}
          />
        )}
      </div>
    </div>
  )
}

export default SignInPage
