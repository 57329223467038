import React, { ReactNode, useState } from 'react'
import { Description } from 'components/typography'
import cs from 'classnames'
import Eye from 'images/icons/eye.svg'
import styles from './Input.module.scss'

// @ts-expect-error
interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  customclass?: string
  error?: string
  isDisabled?: boolean
  autocomplete?: string
  inputContainerClass?: string
  value: string | number
  onChange: (value: string) => void
  title?: string
  svg?: ReactNode
  required?: boolean
  isHiddenValue?: boolean
}

const Input: React.FC<InputProps> = ({
  className: customclass,
  svg,
  error,
  type,
  title,
  placeholder,
  name,
  id,
  value,
  onChange,
  isDisabled,
  onFocus,
  onBlur,
  required,
  autocomplete,
  inputContainerClass,
  ...other
}) => {
  const handleChange = (ev): void => {
    onChange?.(ev.target.value)
    onChange?.(ev.target.value)
  }
  const [isHiddenValue, setIsHiddenValue] = useState(true)

  return (
    <div className={inputContainerClass}>
      <Description className={styles.title}>
        {title} {required && <span>*</span>}
      </Description>

      <div className={styles.inputWrapper}>
        {svg}
        <input
          aria-label={id}
          autoComplete={autocomplete}
          className={cs(styles.input, svg && styles.withIcon, customclass, {
            [styles.invalidInput]: !!error,
          })}
          disabled={isDisabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          onFocus={onFocus}
          placeholder={placeholder}
          type={isHiddenValue ? type : 'text'}
          value={value}
          {...other}
        />
        {type === 'password' && (
          <div
            aria-label={isHiddenValue ? 'show password' : 'hide password'}
            className={styles.eye}
            onClick={(): void => setIsHiddenValue(!isHiddenValue)}
            onKeyDown={(): void => setIsHiddenValue(!isHiddenValue)}
            role='button'
            tabIndex={0}
          >
            <div
              className={cs(styles.line, !isHiddenValue && styles.transparent)}
            />
            <Eye />
          </div>
        )}
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  )
}
export default Input
