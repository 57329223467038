import React, { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { GetServerSideProps } from 'next'
import { Message } from 'interfaces/pages-components-interfaces'
import { ApiHelperContext } from 'components/context/ApiHelperContext'
import SignInPageStore from 'store/SignInPageStore'
import MetaTags from 'components/shared/meta-tags/MetaTags'
import PageLoader from 'components/ui/page-loader/PageLoader'
import SignInPage from 'components/pages/sign-in-page/SignInPage'
import { SignInPageContext } from '../src/components/context/SignInPageContext'

const Join: React.FC = () => {
  const { query } = useRouter()
  const store = new SignInPageStore(useContext(ApiHelperContext))
  const confirmationToken = query['confirmation-token']?.toString() || null
  const apiHelper = useContext(ApiHelperContext)
  const [notificationData, setNotificationData] = useState<Message>()
  const [isLoading, setIsLoading] = useState(true)

  const getData = async (): Promise<void> => {
    setIsLoading(true)
    const notificationMessage = confirmationToken
      ? await apiHelper.emailConfirm(confirmationToken)
      : null
    setNotificationData(notificationMessage as Message)
    setIsLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <SignInPageContext.Provider value={store}>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <MetaTags title='Sign In' />
          <SignInPage notificationData={notificationData} />
        </>
      )}
    </SignInPageContext.Provider>
  )
}

export const getServerSideProps: GetServerSideProps = async () => ({
  props: {},
})

export default Join
