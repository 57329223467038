import { action, makeObservable, observable } from 'mobx'
import { NextJsApiHelper } from '../helpers/nextJsApiHelper'
import { KruzeKontrolApiHelper } from 'helpers/kruzeKontrolApiHelper'

export class IntegrationPageStore {
  private apiHelper: KruzeKontrolApiHelper
  stepNumber: number = 1
  otpSecret: string = ''
  otpLink: string = ''
  isLoading: boolean = false
  backupCodes: string[] | null = null
  errorMessage: string | number | null = null

  constructor(apiHelper) {
    this.apiHelper = apiHelper

    makeObservable(this, {
      stepNumber: observable,
      otpSecret: observable,
      otpLink: observable,
      isLoading: observable,
      backupCodes: observable,
      errorMessage: observable,
      setStepNumber: action,
      handleFirstStep: action,
      enableOtpCode: action,
    })
  }

  setStepNumber = (number): void => {
    this.stepNumber = number
  }

  handleFirstStep = async (): Promise<void> => {
    this.isLoading = true
    const { result } = await this.apiHelper.getOtpSetupInfo()
    this.otpSecret = result.otp_secret
    this.otpLink = result.otp_secret_link
    this.isLoading = false
    this.setStepNumber(2)
  }

  enableOtpCode = async (otpCode): Promise<void> => {
    this.isLoading = true
    const { result, error } = await this.apiHelper.enableOtpCode(otpCode)
    const nextApiHelper = new NextJsApiHelper()
    await nextApiHelper.updateAccessToken()
    this.isLoading = false

    if (result) {
      this.backupCodes = result.backup_codes
      this.setStepNumber(4)
    } else {
      this.errorMessage = error
    }
  }
}
export default IntegrationPageStore
