import React, { ReactNode, MouseEvent } from 'react'
import cs from 'classnames'
import UiLink from 'components/ui/ui-link/UiLink'
import { ButtonFont } from 'components/typography'
import colors from 'styles/modules/colors.module.scss'
import Loader from 'react-loader-spinner'
import styles from './Button.module.scss'

interface Props {
  buttonType?: 'button' | 'submit' | 'reset'
  size?: 'small' | 'medium' | 'big'
  className?: string
  linkTo?: string
  children: ReactNode | string
  isDisabled?: boolean
  isSecondary?: boolean
  isLoading?: boolean
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  isRed?: boolean
  blank?: boolean
  isTransparent?: boolean
}

const Button: React.FC<Props> = ({
  buttonType,
  className,
  children,
  isSecondary,
  isDisabled,
  linkTo,
  size,
  isLoading,
  isRed,
  blank,
  isTransparent,
  ...other
}) =>
  linkTo ? (
    <UiLink blank={blank} href={linkTo}>
      <button
        className={cs(
          styles.btn,
          className,
          styles[size],
          isTransparent && styles.transparent,
          isSecondary && styles.secondary,
          isDisabled && styles.disabled,
          isRed && styles.red,
        )}
        disabled={isDisabled}
        // eslint-disable-next-line react/button-has-type
        type={buttonType}
        {...other}
      >
        <ButtonFont>{children}</ButtonFont>
      </button>
    </UiLink>
  ) : (
    <button
      className={cs(
        styles.btn,
        className,
        styles[size],
        isTransparent && styles.transparent,
        isSecondary && styles.secondary,
        isDisabled && styles.disabled,
        isRed && styles.red,
      )}
      disabled={isDisabled}
      // eslint-disable-next-line react/button-has-type
      type={buttonType}
      {...other}
    >
      <div className={cs(styles.loader, isLoading && styles.show)}>
        <Loader
          color={isSecondary ? colors.blue5 : colors.white}
          height={25}
          type='Oval'
          width={25}
        />
      </div>
      <ButtonFont className={isLoading && styles.hiddenText}>
        {children}
      </ButtonFont>
    </button>
  )

export default Button
