import React from 'react'
import Head from 'next/head'

const MetaTags: React.FC<{ title?: string }> = ({ title }) => {
  const shownTitle = title ? `Kruze - ${title}` : 'Kruze'

  return (
    <Head>
      <title>{shownTitle}</title>
      <meta content='Kruze' name='description' />
      <link href='/favicon.svg' rel='icon' />
      <meta charSet='UTF-8' />
      <meta content='website' property='og:type' />
      <meta content={shownTitle} name='og:title' property='og:title' />
      <meta content='Kruze' property='og:site_name' />
      <meta
        content='width=device-width, initial-scale=1.0, maximum-scale=2.0'
        name='viewport'
      />
      <meta content='summary_large_image' name='twitter:card' />
      <meta content={shownTitle} name='twitter:title' />
      <meta content='Kruze' name='twitter:description' />
      <meta content='noindex, nofollow' name='robots' />
      <meta content='Kruze' name='apple-mobile-web-app-title' />
      <meta content='Kruze' name='application-name' />
    </Head>
  )
}

export default MetaTags
